.misc {
    header {
      height: 160px;
    }

    h1 {
      max-width: 100%;
      text-align: center;
    }

}