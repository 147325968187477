@charset "UTF-8";
body {
  margin: 0;
  direction: rtl;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

footer {
  width: 90%;
  font-size: 11px;
  margin-top: 30px;
  margin: 30px auto 0; }

/* main {
  max-width: 90%;
  margin: 0 auto;
} */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

header {
  position: relative;
  height: 260px;
  background: url("./assets/cover2.jpg") center center no-repeat;
  background-size: cover; }

header:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #001340;
  background: -moz-linear-gradient(top, rgba(0, 19, 64, 0.8) 0, rgba(0, 30, 102, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 19, 64, 0.8) 0, rgba(0, 30, 102, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 19, 64, 0.8) 0, rgba(0, 30, 102, 0) 100%); }

.app {
  position: relative; }
  .app header nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 42px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08); }
    .app header nav li {
      float: right;
      list-style: none;
      padding: 10px; }
      .app header nav li a {
        color: #fff; }
  .app .logo {
    position: absolute;
    width: 120px;
    top: 10px;
    left: 30px; }
  .app h1 {
    font-size: 28px;
    line-height: 40px;
    position: absolute;
    top: 100px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 4px;
    padding: 0;
    color: #fff;
    box-sizing: border-box;
    margin: 0;
    text-shadow: 2px 2px 2px #555555;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; }

div > h2 {
  margin-top: 1.5em; }

.ant-btn.ant-btn-loading {
  padding-right: 29px !important;
  padding-left: 8px !important; }

.anticon.anticon-loading {
  margin-left: 14px !important;
  margin-right: -14px !important; }

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px; }

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px; }

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px; }

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  cursor: pointer; }

.ol-popup-closer:after {
  content: "✖"; }
