.misc.phones {
    th.ant-table-cell {
        &:empty {
            display: none;
        }
    }

    .ant-table-cell {
        .phone {
            &:after {
                content: ' | ';
            }

            &:last-of-type:after {
                content: '';
            }
        }
    }

    .ant-table-row-level-0 .ant-table-cell {
        font-size: 22px;
        font-weight: 900;
        background-color: #f0f0f0;
    }

    .ant-table-row-level-1 .ant-table-cell {
        font-size: 18px;
        font-weight: 900;
        background-color: #fafafa;
    }
}
