.searchInput {
  position: absolute;
  width: 330px;
  top: 180px;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 10px;
  height: 60px;
  display: flex;
  align-items: center;

  input {
    margin: 0;
    margin-left: 11px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;

    height: 40px;
    padding: 6px 11px;
    font-size: 16px;
    line-height: 40px;
    line-height: 1.5 \9;

    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px !important;
    }
  }
}
