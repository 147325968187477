@charset "UTF-8";
.roads header {
  height: 160px;
  background-image: url("./assets/road-trip.jpg");
  background-position: bottom;
  margin-bottom: 5px; }

.roads .share-btn {
  position: absolute;
  top: 170px;
  left: 10px; }

.roads .car-btn {
  position: absolute;
  top: 170px;
  left: 30px; }

.roads div > h2 {
  margin-top: 0.5em; }

.roads .btn {
  cursor: pointer;
  margin-bottom: 10px; }

.roads .road-item {
  padding: 0 10px; }

.roads .ol-viewport .highlight,
.roads .ol-viewport .marker {
  display: inline-block; }

.roads #weatherImg {
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background-color: whitesmoke;
  opacity: 0.75; }

.roads .marker {
  display: none;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #f00;
  transform: rotate(-45deg) translateX(60%) translateY(-15%);
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  z-index: 2; }
  .roads .marker:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 6px 6px 0 8px;
    background: #0c0c2c;
    position: absolute;
    border-radius: 50%; }
  .roads .marker .pulse {
    background: rgba(12, 12, 44, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: skew(30deg, 10deg);
    z-index: -2; }
    .roads .marker .pulse:after {
      content: "";
      border-radius: 50%;
      height: 40px;
      width: 40px;
      position: absolute;
      margin: -13px 0 0 -13px;
      animation: pulsate 1s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
      box-shadow: 0 0 1px 2px #89849b;
      animation-delay: 1.1s; }

.roads .is-closed a {
  text-decoration: line-through; }

.roads .is-closed:before {
  content: " כביש סגור";
  padding-left: 10px;
  font-weight: bold;
  color: #c00542; }

.roads h2 {
  display: flex;
  align-items: center; }
  .roads h2 i {
    margin-left: 10px; }

.roads .elev-chart {
  overflow: hidden; }

.roads .highlight {
  display: none;
  padding: 5px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 2px 2px #3366cc; }

.roads .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px; }

.roads .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.roads .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0; }

.roads .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.roads .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0; }

.roads .ant-btn-group .ant-btn + .ant-btn,
.roads .ant-btn + .ant-btn-group,
.roads .ant-btn-group span + .ant-btn,
.roads .ant-btn-group .ant-btn + span,
.roads .ant-btn-group > span + span,
.roads .ant-btn-group + .ant-btn,
.roads .ant-btn-group + .ant-btn-group {
  margin-right: -1px; }

.roads .road-header-title {
  display: inline-block;
  padding-left: 10px; }

.ant-tooltip-content ul {
  padding-right: 10px; }

.ant-tooltip-content li {
  list-style: disc;
  margin-right: 10px; }

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0; } }

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg); }
  80% {
    transform: translateY(-10px) rotate(-45deg); }
  100% {
    transform: translateY(0) rotate(-45deg); } }
