 .compare-row {
     &:nth-child(even) {
         background: #cae5ff;

     }

     &.compare-subtitle:nth-child(even) {
         background-color: #cae5ff88;
     }

     td {
         padding: 10px;
     }

     &:first-of-type {
         position: sticky;
         top: 0;
         z-index: 111;
         background: white;


     }
 }



 .compare-title {

     position: sticky;
     top: 64px;
     z-index: 111;
     background: white;
     box-shadow: 0px 10px 10px -10px #666666;

 }