.historySection {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: calc(100% - 20px);

    @media screen and (max-width: 720px) {
        display: none;
    }
}

.historyItem {
  min-width: 120px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px black;
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #001340;
  padding: 7px;
  box-sizing: border-box;
}

.lastItemTitle {
    line-height: 70px;
    font-size: 16px;
    font-weight: 700;
    padding: 0 10px;
    color: #fff;
}
