@charset "UTF-8";
.app.car {
  height: 400px; }

.carDetails {
  max-width: 90%;
  min-width: 320px;
  margin: 20px auto 0;
  position: relative; }

.row {
  display: flex;
  flex-direction: row; }
  .row + span {
    margin-right: 10px; }

.cell {
  display: table-cell;
  padding-left: 10px;
  vertical-align: top; }

.half {
  display: table-cell;
  padding-left: 10px;
  vertical-align: top;
  width: calc(50% - 5px); }

.cell:last-child {
  padding-left: 0; }

.stolen {
  font-size: 26px;
  font-weight: bold;
  color: red;
  margin: 10px auto;
  text-align: center;
  display: block; }

.stolen:after {
  font-size: 16px;
  padding-right: 10px;
  content: "   יש לצלצל 100! "; }

ul {
  padding-right: 0; }

li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  li strong {
    padding-left: 4px; }
