@charset "UTF-8";
.checked {
  font-weight: bold; }
  .checked:before {
    content: "√ ";
    font-weight: normal; }
  .checked[data-install]:after {
    content: "(מקור התקנה: " attr(data-install) ")";
    font-weight: normal;
    padding-right: 5px; }

.missing {
  text-decoration: line-through; }

.sfBox {
  position: relative;
  width: 23px;
  height: 23px;
  text-align: center;
  padding: 4px;
  color: white;
  display: inline-block;
  margin: 2px;
  font-size: 12px;
  line-height: 15px; }

.sfSelected:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #000000 transparent transparent transparent;
  position: absolute;
  top: -9px;
  left: calc(50% - 6px); }

.sfScale {
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  width: 243px;
  margin: 0 auto;
  padding: 7px 0 16px 0; }

.sfHigh,
.sfLow {
  display: block;
  width: auto;
  position: absolute;
  top: 32px;
  border-top: 1px solid lightgrey;
  line-height: 15px; }

.sfHigh {
  right: 0;
  text-align: right; }

.sfLow {
  left: 0;
  text-align: left; }

.sfType0 {
  background-color: #a72c32; }

.sfType1 {
  background-color: #f15a22; }

.sfType2 {
  background-color: #ffcb05; }

.sfType3 {
  background-color: #26b78d; }

.sfType4 {
  background-color: #3189a0; }

.sfType5 {
  background-color: #248bcb; }

.sfType6 {
  background-color: #1b6fb7; }

.sfType7 {
  background-color: #4270b7; }

.sfType8 {
  background-color: #435daa; }
