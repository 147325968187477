
.apScale {
    display: flex;
    align-items: center;
    user-select: none;
    position: relative;
    width: 330px;
    margin: 0 auto;
    padding: 7px 0 16px 0;
  }

  .apBox {
    position: relative;
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 0;
    color: white;
    display: inline-block;
    margin: 1px;
    font-size: 11px;
    line-height: 20px;
    min-width: 20px;
  }

  .apSelected:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #000000 transparent transparent transparent;
    position: absolute;
    top: -9px;
    left: calc(50% - 6px);
  }


  .apMax,
  .apMin {
    display: block;
    width: auto;
    position: absolute;
    top: 26px;
    border-top: 1px solid lightgrey;
    line-height: 15px;
  }

  .apMax {
    right: 0;
    text-align: right;
  }

  .apMin {
    left: 0;
    text-align: left;
  }

  .apTypes1,
  .apTypes2,
  .apTypes3 {
    background-color: #005428;
    color: white !important;
  }
  .apTypes4,
  .apTypes5 {
    background-color: #487a46;
    color: white !important;
  }
  .apTypes6,
  .apTypes7 {
    background-color: #41ad49;
    color: black !important;
  }
  .apTypes8,
  .apTypes9 {
    background-color: #fff200;
    color: black !important;
  }
  .apTypes10,
  .apTypes11 {
    background-color: #ebcb00;
    color: black !important;
  }
  .apTypes12,
  .apTypes13 {
    background-color: #f47920;
    color: black !important;
  }
  .apTypes14,
  .apTypes15 {
    background-color: #be1f24;
    color: white !important;
  }
