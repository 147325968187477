.delek {
  header {
    height: 160px;
  }

  h1 {
    max-width: 100%;
    text-align: center;
  }


  .ol-viewport {
    .ol-popup,
    .marker {
      display: inline-block;
    }
  }

  .ol-popup {display: none;}
  .marker {
    display: none;
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #f00;
    // position: absolute;
    transform: rotate(-45deg) translateX(60%) translateY(-15%);
    // left: 50%;
    // top: 50%;
    margin: -20px 0 0 -20px;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    z-index: 2;

    &:after {
      content: "";
      width: 14px;
      height: 14px;
      margin: 6px 6px 0 8px;
      background: #0c0c2c;
      position: absolute;
      border-radius: 50%;
    }

    .pulse {
      background: rgba(#0c0c2c, 0.2);
      border-radius: 50%;
      height: 14px;
      width: 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: 11px 0px 0px -12px;
      transform: skew(30deg, 10deg);
      z-index: -2;

      &:after {
        content: "";
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -13px 0 0 -13px;
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        box-shadow: 0 0 1px 2px #89849b;
        animation-delay: 1.1s;
      }
    }
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
