.tavBox {
  display: inline-block;
  width: 205px;
  height: 130px;
  vertical-align: middle;
  z-index: 10000;
  position: relative;
}

.tavType01 {
  background: url("./assets/tav-1.jpeg") no-repeat;
  background-size: cover;
}
.tavType02 {
  background: url("./assets/tav-2.jpeg") no-repeat;
  background-size: cover;
}
.tavType03 {
  background: url("./assets/tav-3.jpeg") no-repeat;
  background-size: cover;
}
.tavType04 {
  background: url("./assets/tav-4.jpeg") no-repeat;
  background-size: cover;
}
