// @import row from '../App.scss';

.row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 565px) {
    flex-direction: column;
  }
}

.ncap {
  max-width: 90%;
  margin: 0 auto;
}

.half {
  display: table-cell;
  padding: 0 10px;
  vertical-align: top;

  width: calc(50% - 5px);
  strong {
    display: inline-block;
    width: 160px;
  }

  @media screen and (max-width: 565px) {
    width: 100%;
    flex-direction: column;
  }
}

.ncapImage {
  width: 100%;
  height: auto;
}
